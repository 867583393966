<template>
  <div v-if="user" class="mb-3">
    <h4>Willkommen {{ user.title }} {{ user.surname }}!</h4>
    <p>Ihre Benutzer-ID: {{ user._id }}</p>
    <p v-if="user.isSuperuser" class="bg-danger text-white p-3 rounded">
      Sie sind Superuser! Nutzen Sie Ihre Befugnisse mit Vorsicht!
    </p>

    <div class="mt-5">

      <h5 class="display-6">06. November 2022 <span v-if="new Date().getTime() < 1670366566000" class="badge bg-success">Neue Funktionen</span></h5>
      <h4>Features</h4>
      <ul>
        <li>Duplikate von eRezepten werden jetzt nicht mehr fälschlich erkannt. Name, Geburtsdatum und eRezept-Link werden geprüft.</li>
        <li>eRezepte enthalten jetzt die Medikationen des Rezepts und den vollständigen Rezepttext.</li>
      </ul>

      <a class="btn btn-secondary" data-bs-toggle="collapse" href="#collapseChangelogs" role="button" aria-expanded="false" aria-controls="collapseChangelogs">
        Vorangegangene Changelogs
      </a>

    </div>

    <div class="collapse" id="collapseChangelogs">

      <h5 class="display-6">12. März 2022</h5>
      <h4>Features</h4>
      <ul>
        <li>Es können nun neben Impf- auch Genesenenzertifikate vom Programm verarbeitet werden.</li>
        <li>Alte Changelogs sind jetzt versteckt.</li>
        <li>Der Hinweis "Neue Funktionen" verschwindet 15 Tage nach einem Update.</li>
      </ul>

      <h5 class="display-6">24. Januar 2022</h5>
      <h4>Features</h4>
      <ul>
        <li>Das Drucklayout wurde angepasst, sodass die Impfinfo "Impfung x von y" ein schön eingereihtes Feld mit den anderen ist.</li>
        <li>Benutzer mit der Rolle "ImmunkarteCreator" können nun Immunkarten als ungedruckt markieren. Dies wird im Protokoll der Karte erfasst.</li>
      </ul>

      <h5 class="display-6">19. Januar 2022</h5>
      <h4>Features</h4>
      <ul>
        <li>Immunkarten können jetzt durch Benutzer mit der Rolle "ImmunkarteCreator" gelöscht werden.</li>
      </ul>


      <h5 class="display-6">18. Januar 2022</h5>

      <h4>Bugfixes</h4>
      <ul>
        <li>Suche nach ungedruckten Immunkarten funktioniert wieder</li>
      </ul>

      <h4>Features</h4>
      <ul>
        <li>Jeder durchgeführte Druck wird mit Zeit- und Nutzerstempel protokolliert. Dies kann unter "Alle Immunkarten" in der Detailansicht einer Immunkarte eingesehen werden.</li>
      </ul>

      <h5 class="display-6">15. Januar 2022</h5>
      <h4>Features</h4>
      <ul>
        <li><strong>Booster</strong>: Es werden für einen Patienten immer die höchsten bzw. neusten Impfungen gedruckt. Wenn man 2/2 und 3/3 im gleichen Druckauftrag auswählt, wird nur 3/3 gedruckt.</li>
        <li><strong>Booster</strong>: Hinter Impfung 3 von 3 steht jetzt zusätzlich "Booster"</li>
      </ul>
      <h4>Bugfixes</h4>
      <ul>
        <li><strong>Einlesen von Immunkarten</strong> Vor- und Nachnamen die einen Bindestrich enthalten werden korrekt erkannt</li>
        <li>Die Suche nach Vor- und Nachname funktioniert wieder für "Alle" und "Ungedruckte" Immunkarten</li>
        <li><strong>Sitzungsablauf</strong> Wenn die Sitzung eines Benutzers abgelaufen ist, wird er automatisch zur Anmeldung weitergeleitet</li>
        <li>Die Navigationsleiste wird automatisch nach Anmeldung aktualisiert</li>
      </ul>
    </div>


    <p class="mt-5">Ihr Access-Token: </p>
    <code>{{ user.accessToken }}</code>

    <h6 class="mt-5">Ihre Zugriffsrechte</h6>
    <table class="table mt-3">
      <caption>
        Dies sind alle Rollen bzw. Berechtigungen die Sie für den jeweiligen Mandanten haben.
      </caption>
      <thead>
      <tr>
        <th id="role">Rolle</th>
        <th id="mandant">Mandant</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in user.permissions" :key="item.role">
        <td>{{ item.role.name }}</td>
        <td>{{ item.mandant.firmenbezeichnung.voll }}</td>
      </tr>
      </tbody>
    </table>
    <div class="mb-3">
      <button class="btn btn-primary text-white" @click="signOut">Abmelden</button>
    </div>
  </div>
</template>

<script>
import AuthService from "../services/auth.service";

export default {
  name: "AuthUserInfo",

  computed: {
    user() {
      return this.$store.state.auth.user
    }
  },

  methods: {
    signOut() {
      AuthService.signOut()
      this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style scoped>

ul {
  line-height: 200%
}

</style>
